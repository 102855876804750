import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import MainPage from './components/MainPage';
import WorkRange from './components/WorkRange';
import About from './components/About';
import Values from './components/Values';
import Contact from './components/Contact';
import Header from './components/Header';

function App() {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route element={<MainPage />} path='/' />
          <Route element={<WorkRange />} path='/zakres-dzialalnosci' />
          <Route element={<About />} path='/o-mnie' />
          <Route element={<Values />} path='/nasze-wartosci' />
          <Route element={<Contact />} path='/kontakt' />
        </Routes>
      </Router>
    </>
  );
}

export default App;
