import React from 'react';
import Lady from '../images/gray.png';

type Props = {};

export default function MainPage({}: Props) {
	return (
		<main className="w-full">
			<img src={Lady} className="mx-auto h-[200px]" />
			<div className="w-full bg-darkestGreen quote-container">
				<span className="quote-text">
					„Nie można być wzorowym adwokatem, <br />
					jeśli nie jest się przyzwoitym człowiekiem”
				</span>
				<label className="font-[15px]">Antoine-Gaspard Boucher d'Argis</label>
			</div>
		</main>
	);
}
