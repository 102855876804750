import React from 'react';
import Building from '../images/building.jpg';

type Props = {};

export default function Contact({}: Props) {
	return (
		<>
			<div className="mt-5">
				<img src={Building} className="contact-building" />
				<div className="contact-text z-10 w-full py-16 font-bold text-[24px] text-center text-white flex flex-col gap-5">
					<span>
						Kancelaria Adwokacka <br />
						Magdalena Łopatowska-Mikołajczyk
					</span>

					<span>
						ul. Wilcza 29a lok. 7 <br />
						00-544 Warszawa
					</span>

					<span>+48 518 619 989</span>

					<a href="mailto:biuro@kancelariamlm.pl">biuro@kancelariamlm.pl</a>
				</div>
			</div>
		</>
	);
}
