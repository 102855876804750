import React from 'react';

type Props = {};

export default function About({}: Props) {
	return (
		<div className="site-content">
			<div className="text-holder">
				<p>
					Jestem absolwentką prawa Uniwersytetu Kardynała Stefana Wyszyńskiego w Warszawie oraz Członkiem Okręgowej Rady Adwokackiej w Warszawie.
				</p>
				<p>
					Doświadczenie zdobywałam w renomowanych warszawskich kancelariach, zajmując się prowadzeniem postępowań sądowych oraz bieżącą obsługą
					przedsiębiorców. Pracuję zarówno z klientami indywidualnymi, jak i zarządami dużych podmiotów gospodarczych. W trakcie swojej praktyki
					przygotowywałam do wykonywania zawodu aplikantów adwokackich i studentów prawa.
				</p>
				<p>
					Reprezentuję klientów indywidualnych w sporach z zakresu prawa rodzinnego, opiekuńczego spadkowego, cywilnego, prawa pracy oraz błędów
					medycznych. Z sukcesem zakończyłam kilkadziesiąt postępowań sądowych. Brałam udział w kilkuset rozprawach sądowych w całej Polsce.
					Prowadzę sprawy we wszystkich województwach.
				</p>
				<p>
					Zajmuję się również obsługą przedsiębiorców z branży oświatowej, transportowej oraz budowlanej. Pełnię funkcję stałego doradcy
					właścicieli i zarządów podmiotów gospodarczych w ww. branżach. Wspieram także organizacje pozarządowe.
				</p>
			</div>
		</div>
	);
}
