import React, { useEffect } from 'react';
import MenuIcon from '../icons/menu.svg';
import Logo from '../icons/logo_wektor.png';
import { Link, useLocation } from 'react-router-dom';

type Props = {};

export default function Header({}: Props) {
	const location = useLocation();
	const linksOrder: string[] = ['/', '/zakres-dzialalnosci', '/o-mnie', '/nasze-wartosci', '/kontakt'];

	useEffect(() => {
		if (location.pathname === '/kontakt') {
			document.querySelector('.header-list')?.classList.add('gradient');
			document.querySelector('.header-list')?.classList.remove('bg-[#C9E3DE]');
		} else {
			document.querySelector('.header-list')?.classList.remove('gradient');
			document.querySelector('.header-list')?.classList.add('bg-[#C9E3DE]');
		}

		handleLocationChange(linksOrder.indexOf(location.pathname));
	}, [location]);

	function selectEl(e: string) {
		return document.querySelector(e);
	}

	function closeModal() {
		selectEl('.overlay')?.classList.remove('active');
		selectEl('.modal')?.classList.remove('active');
	}

	function menuTriggered() {
		selectEl('.overlay')?.classList.add('active');
		selectEl('.modal')?.classList.add('active');
	}

	function handleLocationChange(e: number) {
		const modalItems: NodeListOf<HTMLLIElement> = document.querySelectorAll<HTMLLIElement>('.modal-list > li');
		const listItems: NodeListOf<HTMLLIElement> = document.querySelectorAll<HTMLLIElement>('.header-list > li');
		modalItems.forEach((element: HTMLLIElement) => {
			element.classList.remove('active');
		});
		listItems.forEach((element: HTMLLIElement) => {
			element.classList.remove('active');
		});

		const liMain: HTMLLIElement = listItems[e];
		const liModal: HTMLLIElement = modalItems[e];

		liMain.classList.add('active');
		liModal.classList.add('active');
	}

	return (
		<>
			<div className="overlay" onClick={closeModal}></div>
			<div className="modal">
				<ul className="ml-4 mt-5 flex flex-col gap-4 py-5 modal-list">
					<li onClick={closeModal}>
						<Link to="/">start</Link>
					</li>
					<li onClick={closeModal}>
						<Link to="zakres-dzialalnosci">zakres działalności</Link>
					</li>
					<li onClick={closeModal}>
						<Link to="o-mnie">o mnie</Link>
					</li>
					<li onClick={closeModal}>
						<Link to="nasze-wartosci">nasze wartości</Link>
					</li>
					<li onClick={closeModal}>
						<Link to="kontakt">kontakt</Link>
					</li>
				</ul>
			</div>
			<header className="relative pt-5 site-header">
				<img src={MenuIcon} className="h-7 w-7 menu-icon" onClick={menuTriggered} alt="menu" />
				<div className="logo-holder flex flex-col gap-1 items-center w-full">
					<Link to="/" className="contents">
						<img src={Logo} className="h-[90px] mx-auto" alt="logo" />
						<strong>kancelaria adwokacka</strong>
						<label className="cursor-pointer">
							MAGDALENA <br />
							ŁOPATOWSKA - MIKOŁAJCZYK
						</label>
					</Link>
				</div>
				<ul className="w-full bg-[#C9E3DE] mt-5 flex justify-around py-5 header-list">
					<li>
						<Link to="/">start</Link>
					</li>
					<li>
						<Link to="zakres-dzialalnosci">zakres działalności</Link>
					</li>
					<li>
						<Link to="o-mnie">o mnie</Link>
					</li>
					<li>
						<Link to="nasze-wartosci">nasze wartości</Link>
					</li>
					<li>
						<Link to="kontakt">kontakt</Link>
					</li>
				</ul>
			</header>
		</>
	);
}
