import React from 'react';

type Props = {};

export default function Values({}: Props) {
	return (
		<>
			<div className="site-content">
				<div className="text-holder">
					<p>
						Wyróżnia nas <strong>RZETELNOŚĆ, FACHOWOŚĆ</strong> oraz <strong>WYSOKIE STANDARDY ETYCZNE</strong>
					</p>
					<p>
						Z takim samym zaangażowaniem podchodzimy do każdego klienta, bez względu na rodzaj oraz zakres powierzonych spraw. Na pierwszym
						miejscu zawsze stawiamy jego dobro. Nigdy nie oceniamy, lecz staramy się pomóc.
					</p>
					<p>
						Tam, gdzie to możliwe, pomagamy zawrzeć ugodę, uniknąć wieloletniego sporu oraz ograniczyć koszty dochodzenia swoich praw. W
						trudnych życiowych sprawach staramy się dawać wsparcie nie tylko prawne, ale również emocjonalne. Umiemy nie tylko przemawiać, ale
						też słuchać.
					</p>
					<p>
						Pracownikom i konsumentom pomagamy zmierzyć się z potęgą wielkich przedsiębiorców. Udowadniamy, że w postępowaniu każdy podmiot ma
						takie same prawa, bez względu na wysokość posiadanego kapitału.
					</p>
					<p>
						Przedsiębiorcom pomagamy natomiast zadbać o biznes, zarówno ochronić go przed nieuczciwymi kontrahentami jak i rozwinąć, aby
						przynosił lepsze zyski. Wspieramy klientów swoim doświadczeniem, aby pomóc im uniknąć niepotrzebnych sporów. Załatwiamy za klientów
						formalności w sądach i urzędach, aby mogli się skupić na tym co ważne.
					</p>
					<p>
						Organizacjom pozarządowym pomagamy zorganizować formalności, aby mogły w pełni poświęcić się realizacji swoich celów statutowych.
					</p>
					<p>
						Każdą sprawę prowadzimy tak, jakby była to nasza najważniejsza życiowa sprawa. W każdej sprawie walczymy do końca, aby na koniec
						klient miał pewność, że w jego sprawie zrobiliśmy wszystko, co było możliwe.
					</p>
				</div>
			</div>
		</>
	);
}
