import React from 'react';

type Props = {};

export default function WorkRange({}: Props) {
	return (
		<>
			<div className="workrange-content">
				<section className="flex gap-4 mt-5">
					<label className="workrange-label">klienci indywidualni:</label>
					<div className="text-holder">
						<p>
							<strong>SPRAWY RODZINNE</strong> – prowadzimy postępowania rozwodowe, zarówno te ugodowe, jak i mocno konfliktowe - z orzekaniem
							o winie oraz sprawy o unieważnienie małżeństwa. Pomagamy sporządzić porozumienie okołorozwodowe a także porozumienie wychowawcze.
							Prowadzimy sprawy o ustanowienie rozdzielności majątkowej, w tym z datą wsteczną, sprawy o podział majątku wspólnego (w ramach
							których dzielimy wielomilionowe majątki) a także o zwrot nakładów pomiędzy małżonkami i konkubentami.
						</p>
						<p>
							<strong>SPRAWY OPIEKUŃCZE</strong> – prowadzimy spory dotyczące alimentów, władzy rodzicielskiej oraz kontaktów z dziećmi.
							Pomagamy również, przy użyciu dostępnych środków prawnych, wyegzekwować niepłacone alimenty oraz nierealizowane kontakty.
						</p>
						<p>
							<strong>SPRAWY CYWILNE</strong> – prowadzimy postępowania o zapłatę, o wydanie ruchomości oraz nieruchomości, sprawy o
							zasiedzenie, o ustalenie przebiegu granic, sprawy odszkodowawcze, w tym przeciwko ubezpieczycielom. Zajmujemy się również
							windykacją należności.
						</p>
						<p>
							<strong>SPRAWY SPADKOWE</strong> – prowadzimy sprawy o stwierdzenie nabycia spadku, dział spadku oraz zniesienie współwłasności
							powstałej w wyniku nabycia spadku, zachowek, przyjęcie i odrzucenie spadku, w tym w postępowaniu o udzielenie zezwolenia na
							odrzucenie spadku przez małoletniego, uchylenia się od skutków prawnych oświadczenia o przyjęciu lub odrzucenia spadku.
						</p>
						<p>
							<strong>SPRAWY PRACOWNICZE</strong> - reprezentujemy pracowników w sporach z pracodawcami związanych z istniejącym stosunkiem
							pracy, jak i po jego rozwiązaniu (spawy o zapłatę wynagrodzenia, wynagrodzenia za pracę w godzinach nadliczbowych, odszkodowania
							z tytułu klauzuli o zakazie konkurencji, sprostowanie świadectwa pracy, przywrócenie do pracy). Reprezentujemy i doradzamy
							również organizacjom pracowników – w tym związkom zawodowym.
						</p>
						<p>
							<strong>SPRAWY Z ZAKRESU UBEZPIECZEŃ SPOŁECZNYCH</strong> – prowadzimy spory z ZUS dotyczące kwestii zabezpieczenia społecznego,
							w tym także na etapie sądowym. Pomagamy w kwestiach dotyczących uzyskania emerytury i emerytury pomostowej, podleganiu
							ubezpieczeniom społecznym, w sprawach odwołań od decyzji organów rentowych, w kwestii odprowadzania składek na ubezpieczenia
							społeczne, a także ustalania i uzyskiwania przez pracowników prawa do innych świadczeń z zakresu ubezpieczeń społecznych.
						</p>
						<p>
							<strong>BŁĘDY MEDYCZNE</strong> - reprezentujemy pacjentów w sprawach roszczeń przeciwko szpitalom i innym podmiotom leczniczym w
							zakresie błędów w sztuce medycznej oraz w zakresie przestrzegania szeroko pojętych prawa pacjenta oraz ochrony danych medycznych
							pacjenta.
						</p>
					</div>
				</section>
				<section className="flex gap-4 mt-20">
					<label className="workrange-label">przedsiębiorcy:</label>
					<div className="text-holder">
						<p>
							Oferujemy kompleksową obsługę prawną przedsiębiorców, zarówno działających w ramach jednoosobowej działalności, jak również w
							formie spółek osobowych i kapitałowych. Współpracujemy z małymi, średnimi i dużymi przedsiębiorstwami z branż oświatowej,
							transportowej oraz budowlanej. W ramach stałej obsługi prawnej obsługujemy organy spółek, ich posiedzenia oraz przygotowujemy
							uchwały, tworzymy regulaminy wewnętrzne, umowy o pracę, kontrakty menedżerskie, tworzymy oraz opiniujemy umowy zawierane w ramach
							działalności, reprezentujemy przedsiębiorców przed sądami, organami administracji i organami ścigania, prowadzimy windykację
							należności. Zakres działań jest zawsze dopasowany do potrzeb i problemów prawnych danego przedsiębiorcy.
						</p>
					</div>
				</section>
				<section className="flex gap-4 my-20">
					<label className="workrange-label">
						organizacje <br /> pozarządowe:
					</label>
					<div className="text-holder">
						<p>
							Oferujemy kompleksową obsługę prawną organizacji pozarządowych i społecznych: stowarzyszeń oraz fundacji (prowadzących
							działalność gospodarczą oraz nie prowadzących takiej działalności). Pomagamy w założeniu organizacji oraz w bieżącej obsłudze
							działalności, w tym przygotowujemy m.in. akty założycielskie, statuty (wraz z rejestracją w KRS), regulaminy, umowy (w
							szczególności umowy sponsoringu, darowizny, wolontariatu, najmu lub użyczenia lokalu), dokumenty związane z zatwierdzeniem
							rocznego sprawozdania finansowego. Pomagamy również członkom stowarzyszeń i fundacji w rozwiązywaniu bieżących problemów prawnych
							związanych z prowadzeniem organizacji.
						</p>
					</div>
				</section>
			</div>
		</>
	);
}
